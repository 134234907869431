import React from 'react';
import type { Message as MessageType } from 'helpers/types';
import './Message.scss';
import classNames from 'classnames';
import { images } from 'assets';

type Props = MessageType & {
  owner: boolean;
};

const defaultProps = {};

function Message({ content, user, owner }: Props): JSX.Element {
  return (
    <div className="row flex-nowrap">
      <div
        className={classNames('col w-full', {
          'order-1': !owner,
        })}
      >
        <div
          className={classNames('message', {
            'is-owner': owner,
          })}
        >
          <div className="message__content">
            <p className="text-15 xl:text-16 leading-25 xl:leading-28 text-black-75">{content}</p>
          </div>
        </div>
      </div>
      <div
        className={classNames('hidden lg:block col w-auto flex-shrink-0', {
          'text-right pl-24': owner,
          'pr-24': !owner,
        })}
      >
        <img
          className="w-64 h-64 rounded-full inline-block"
          src={user.profilePhoto ? user.profilePhoto.src : images.avatar}
          alt={user.name}
          onError={(e) => {
            const target = e.target as HTMLImageElement;

            target.src = images.avatar;
          }}
        />
      </div>
    </div>
  );
}

Message.defaultProps = defaultProps;

export default Message;
