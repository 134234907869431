import React from 'react';
import { Alert, Pagination } from 'components';
import { Order, Rate, OrderSummary, Stages } from 'layouts';
import Loading from 'features/loading/Loading';
import { useTranslation } from 'react-i18next';
import { AlertStatus, OfferStatus, OrderList } from 'helpers/enums';
import { useOrders } from 'hooks';
import { OrderAddReview } from 'forms';

const Finished = (): JSX.Element => {
  const { t } = useTranslation(['alert', 'text']);
  const [list] = useOrders(OrderList.finished, 'CLIENT_ORDERS_FINISHED');

  const renderOrders = () =>
    list.items.map((item) => {
      return (
        <Order key={item.id} {...item}>
          <div className="border-t border-black-10 pt-12 xl:pt-16">
            <Stages stages={item.stages} idOrder={item.id} refreshFn={() => list.setPage(1)} />
          </div>
          <div className="border-t border-black-10 mt-12 xl:mt-16 pt-12 xl:pt-16">
            {item.rate ? (
              <Rate {...item.rate} title={t('text:review')} />
            ) : (
              <OrderAddReview id={item.id} onSuccess={() => list.setPage(1)} />
            )}
            <div className="mt-16 xl:mt-24">
              <OrderSummary
                idOrder={item.id}
                offers={item.offers}
                status={OfferStatus.finished}
                titlePrice={t('text:reward')}
              />
            </div>
          </div>
        </Order>
      );
    });

  return (
    <>
      <Loading items={['CLIENT_ORDERS_FINISHED']}>
        {list.items.length ? (
          renderOrders()
        ) : (
          <Alert status={AlertStatus.info} message={t('alert:noResults')} />
        )}
        <Pagination page={list.page} total={list.total} changePage={(v) => list.setPage(v)} />
      </Loading>
    </>
  );
};

export default Finished;
