import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { LoginButton } from 'components';
import { images } from 'assets';
import type { LinkRoute } from 'routes';

type Props = {
  routes: LinkRoute[];
};

function MenuTop({ routes }: Props): JSX.Element {
  return (
    <nav className="hidden xl:block fixed w-full top-0 left-0 z-40 bg-white border-b-2 border-black-5">
      <div className="container">
        <div className="row items-center h-96">
          <div className="col w-1/4">
            <Link to="/" className="block py-8">
              <img src={images.logo} alt="logo" className="h-48" />
            </Link>
          </div>
          <div className="col w-3/4">
            <div className="flex justify-end items-center text-black -mx-20">
              {routes.map((route) => (
                <NavLink
                  key={route.key}
                  to={route.path}
                  exact={route.exact}
                  className="text-16 font-semibold uppercase mx-20 h-96 flex items-center border-b-2 border-transparent"
                  activeClassName="text-primary-dk border-primary-dk"
                >
                  {route.name}
                </NavLink>
              ))}
              <div className="mx-20">
                <LoginButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default React.memo(MenuTop);
