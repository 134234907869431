import React, { useEffect } from 'react';
import { useRoutes } from 'hooks';
import { Link } from 'react-router-dom';
import { Route, LinkRoute } from 'routes';
import { MenuMobile, MenuTop, Footer } from 'layouts';
import { LoginButton } from 'components';

const menuRoutes: LinkRoute[] = [
  {
    key: 'appHome',
    name: 'Strona główna',
    path: '/',
    exact: true,
  },
  {
    key: 'appAbout',
    name: 'O platformie',
    path: '/o-platformie',
    exact: true,
  },
  {
    key: 'appEditors',
    name: 'Redaktorzy',
    path: '/redaktorzy',
    exact: false,
  },
  {
    key: 'appContact',
    name: 'Kontakt',
    path: '/kontakt',
    exact: true,
  },
];

type Props = {
  routes: Route[];
};

function App({ routes }: Props): JSX.Element {
  const [renderRoutes] = useRoutes(routes);

  useEffect(() => {
    document.body.classList.add('pt-80', 'xl:pt-96');

    return () => {
      document.body.classList.remove('pt-80', 'xl:pt-96');
    };
  }, []);

  return (
    <>
      <MenuMobile>
        <ul>
          {menuRoutes.map((route) => (
            <li key={route.key} className="mb-24">
              <Link to={route.path} className="text-16 font-semibold uppercase text-black">
                {route.name}
              </Link>
            </li>
          ))}
        </ul>
        <div className="mt-40">
          <LoginButton />
        </div>
      </MenuMobile>
      <MenuTop routes={menuRoutes} />
      {renderRoutes()}
      <Footer />
    </>
  );
}

export default App;
