import React, { useEffect } from 'react';
import { Alert, Pagination } from 'components';
import { Payment } from 'layouts';
import Loading from 'features/loading/Loading';
import { AlertStatus, PaymentList } from 'helpers/enums';
import { useAppDispatch, useList } from 'hooks';
import { Payment as PaymentType } from 'helpers/types';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { editorService } from 'services';
import { catchApiError } from 'helpers/error';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { useTranslation } from 'react-i18next';

const Paid = (): JSX.Element => {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();
  const list = useList<PaymentType>(10);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'EDITOR_PAYMENTS_PAID' }));

        const params = {
          records: list.perPage,
          page: list.page,
          sortField: 'a.created',
          sortDirection: 'DESC',
        };

        const response = await editorService.payments(params, PaymentList.paid);

        list.handleFetch(response);
      } catch (error) {
        catchApiError(error, () =>
          dispatch(
            addNotification({
              id: 'editor.payments',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        );
      } finally {
        dispatch(removeLoading('EDITOR_PAYMENTS_PAID'));
      }
    })();
  }, [list.shouldUpdate]);

  const renderPayments = () =>
    list.items.map((item) => {
      return <Payment key={item.id} {...item} />;
    });

  return (
    <Loading items={['EDITOR_PAYMENTS_PAID']}>
      {list.items.length ? (
        renderPayments()
      ) : (
        <Alert status={AlertStatus.info} message={t('alert:noResults')} />
      )}
      <Pagination page={list.page} total={list.total} changePage={(v) => list.setPage(v)} />
    </Loading>
  );
};

export default Paid;
