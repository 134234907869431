import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Input, Select, Checkbox, File, Datepicker } from 'components';
import { useAppDispatch, useAppSelector, useFieldsErrors } from 'hooks';
import { authService } from 'services';
import type { User } from 'helpers/types';
import { isFile } from 'helpers/files';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { fetchGlobals, selectorGlobals } from 'features/globals/globalsSlice';
import Loading from 'features/loading/Loading';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';
import { catchApiError } from 'helpers/error';

type TextOrder = {
  subject: string;
  pagesCount: number;
  term: string;
  additionalInfo: string;
  scopeOfOrderId: string;
  typeOfStudyId: string;
  fieldId: string;
  attachmentsToAdd: FileList | null;
};

type Inputs = User & {
  password: string;
  passwordConfirm: string;
  textOrder: TextOrder;
  rulesAccepted: boolean;
  marketingAccepted: boolean;
};

const schema = yup.object().shape({
  email: yup.string().email('Nieprawidłowy adres e-mail').required('To pole jest wymagane'),
  name: yup.string().required('To pole jest wymagane'),
  password: yup.string().required('To pole jest wymagane'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Podane hasła nie są takie same'),
  textOrder: yup.object().shape({
    subject: yup.string().required('To pole jest wymagane'),
    pagesCount: yup.mixed().required('To pole jest wymagane'),
    term: yup.string().required('To pole jest wymagane'),
    scopeOfOrderId: yup.mixed().required('To pole jest wymagane'),
    typeOfStudyId: yup.mixed().required('To pole jest wymagane'),
    fieldId: yup.mixed().required('To pole jest wymagane'),
  }),
  rulesAccepted: yup.mixed().oneOf([true], 'To pole jest wymagane'),
});

function RegisterClient(): JSX.Element {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();
  const globals = useAppSelector(selectorGlobals);

  useEffect(() => {
    dispatch(fetchGlobals(['scopeOfOrders', 'typesOfStudy', 'activityFields']));
  }, []);

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  useFieldsErrors('form-forgot-password', errors);

  const onSubmit = async ({
    email,
    password,
    name,
    textOrder,
    rulesAccepted,
    marketingAccepted,
  }: Inputs) => {
    try {
      const formData = new FormData();

      formData.append('email', email);
      formData.append('password', password);
      formData.append('name', name);
      formData.append('textOrder[subject]', textOrder.subject);
      formData.append('textOrder[pagesCount]', textOrder.pagesCount.toString());
      formData.append('textOrder[term]', textOrder.term);
      formData.append('textOrder[scopeOfOrderId]', textOrder.scopeOfOrderId);
      formData.append('textOrder[typeOfStudyId]', textOrder.typeOfStudyId);
      formData.append('textOrder[fieldId]', textOrder.fieldId);
      formData.append('textOrder[additionalInfo]', textOrder.additionalInfo);

      if (isFile(textOrder.attachmentsToAdd)) {
        Array.from(textOrder.attachmentsToAdd!).forEach((file) => {
          formData.append('textOrder[attachmentsToAdd][]', file);
        });
      }

      formData.append('rulesAccepted', String(rulesAccepted));
      formData.append('marketingAccepted', String(marketingAccepted));

      await authService.registerClient(formData);

      dispatch(
        addNotification({
          id: 'auth.registerClient',
          status: AlertStatus.success,
          message: t('alert:userRegister'),
        }),
      );

      reset();
    } catch (error) {
      catchApiError(
        error,
        () =>
          dispatch(
            addNotification({
              id: 'auth.registerClient',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        setError,
      );
    }
  };

  return (
    <Loading items={['FETCH_GLOBALS']}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="border-b border-black-5 mb-12 xl:mb-16">
          <div className="row">
            <div className="col md:w-1/2 xl:w-1/4">
              <Input control={control} name="email" placeholder="Wpisz" label="Adres e-mail *" />
            </div>
            <div className="col md:w-1/2 xl:w-1/4">
              <Input
                control={control}
                name="name"
                placeholder="Wpisz"
                label="Wyświetlana nazwa *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/4">
              <Input
                type="password"
                control={control}
                name="password"
                placeholder="Wpisz"
                label="Hasło *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/4">
              <Input
                type="password"
                control={control}
                name="passwordConfirm"
                placeholder="Wpisz"
                label="Powtórz hasło *"
              />
            </div>
          </div>
        </div>

        <div className="border-b border-black-5 mb-12 xl:mb-16">
          <div className="row">
            <div className="col xl:w-4/6">
              <Input
                control={control}
                name="textOrder[subject]"
                placeholder="Wpisz"
                label="Temat pracy *"
              />
            </div>
            <div className="col xl:w-1/6">
              <Input
                type="number"
                control={control}
                name="textOrder[pagesCount]"
                placeholder="Wpisz"
                label="Ilość stron *"
              />
            </div>
            <div className="col xl:w-1/6">
              <Datepicker
                control={control}
                name="textOrder[term]"
                label="Termin realizacji *"
                minDate={new Date()}
              />
            </div>
            <div className="col xl:w-2/6">
              <Select
                name="textOrder[scopeOfOrderId]"
                control={control}
                items={globals.scopeOfOrders}
                label="Zakres zlecenia *"
              />
            </div>
            <div className="col xl:w-2/6">
              <Select
                name="textOrder[typeOfStudyId]"
                control={control}
                items={globals.typesOfStudy}
                label="Rodzaj opracowania *"
              />
            </div>
            <div className="col xl:w-2/6">
              <Select
                name="textOrder[fieldId]"
                control={control}
                items={globals.activityFields}
                label="Dziedzina *"
              />
            </div>
          </div>
        </div>

        <Input
          type="textarea"
          control={control}
          name="textOrder[additionalInfo]"
          placeholder="Wpisz pozostałe wytyczne i wymagania"
          label="Dodatkowe informacje"
        />

        <File
          {...register('textOrder.attachmentsToAdd')}
          id="textOrder.attachmentsToAdd"
          label="Załączniki"
          value={watch('textOrder.attachmentsToAdd')}
          resetFn={() => setValue('textOrder.attachmentsToAdd', null)}
          error={errors?.textOrder?.attachmentsToAdd}
          multi
        />

        <Checkbox name="rulesAccepted" control={control} id="rulesAccepted">
          * Akceptuję Regulamin serwisu oraz Politykę Prywatności.
        </Checkbox>

        <Checkbox name="marketingAccepted" control={control} id="marketingAccepted">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id
          volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
          dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce ut placerat orci
          nulla. Tincidunt ornare massa eget egestas purus viverra accumsan in nisl.
        </Checkbox>

        <div className="mt-16 xl:mt-24 text-center">
          <Button as="submit" to="" loading={isSubmitting}>
            Wyślij zlecenie i załóż konto
          </Button>
        </div>
      </form>
    </Loading>
  );
}

export default RegisterClient;
