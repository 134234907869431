import type { Offer, Order, Rate, Stage } from 'helpers/types';
import { parseAttachment, parseAttachments } from 'helpers/attachments';
import { OfferStatus } from 'helpers/enums';

export const mapOffer = ({
  id,
  amount,
  message,
  status,
  hasUnreadMessages,
  creator,
}: any): Offer => ({
  id,
  price: amount,
  message,
  status,
  hasUnreadMessages,
  editor: creator
    ? {
        id: creator.id,
        name: creator.user.name,
        profilePhoto: creator.user.profilePhoto ? parseAttachment(creator.user.profilePhoto) : null,
        avgRate: creator.avgRate,
      }
    : null,
});

export const mapStage = ({
  id,
  stageIndex,
  pages,
  status,
  term,
  attachments,
  correctionsAttachments,
}: any): Stage => ({
  id,
  stageIndex,
  pages,
  status,
  term,
  attachments: attachments ? parseAttachments(attachments) : [],
  correctionsAttachments: correctionsAttachments ? parseAttachments(correctionsAttachments) : [],
});

export const mapRate = ({ id, rate, message, created }: any): Rate => ({
  id,
  rate,
  message,
  created,
});

export const mapRateExtend = ({ id, rate, field, message, created, textOrder }: any): Rate => ({
  id,
  rate,
  title: field ? field.name : '',
  subtitle: textOrder ? textOrder.subject : '',
  message,
  created,
});

export const mapOrder = ({
  id,
  subject,
  creator,
  scopeOfOrder,
  typeOfStudy,
  field,
  pagesCount,
  term,
  additionalInfo,
  attachments,
  offers,
  stages,
  rate,
}: any): Order => ({
  id,
  title: subject,
  user: creator
    ? {
        id: creator.id,
        name: creator.name,
        profilePhoto: null,
      }
    : null,
  label: field ? field.name : null,
  scopeOfOrder,
  typeOfStudy,
  field,
  pagesCount,
  term,
  additionalInfo,
  attachments: attachments ? parseAttachments(attachments) : [],
  offers: offers ? offers.filter((n: any) => n.status !== OfferStatus.canceled).map(mapOffer) : [],
  stages: stages ? stages.map(mapStage) : [],
  rate: rate && rate.length ? mapRate(rate[0]) : null,
});
