import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, File } from 'components';
import { useAppDispatch } from 'hooks';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { orderService } from 'services';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { catchApiError } from 'helpers/error';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';
import { isFile } from 'helpers/files';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';

type Props = {
  id: number;
  idOrder: number;
  correction?: boolean;
  onRequestClose: () => void;
  onSuccess: () => void;
};

const defaultProps = {
  correction: false,
};

type Inputs = {
  attachments: FileList | null;
};

const schema = yup.object().shape({
  attachments: yup.mixed().test('fileRequired', 'To pole jest wymagane', (value) => isFile(value)),
});

function AddStageOfCorrection({
  id,
  idOrder,
  correction,
  onRequestClose,
  onSuccess,
}: Props): JSX.Element {
  const { t } = useTranslation(['alert', 'button', 'text']);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {},
  });

  const onSubmit = async ({ attachments }: Inputs) => {
    try {
      dispatch(addLoading({ id: `STAGE_ACTION_${idOrder}` }));
      dispatch(addLoading({ id: `STAGE_ACTION_TRIGGER_${id}` }));

      const formData = new FormData();

      Array.from(attachments!).forEach((file) => {
        formData.append('attachments[]', file);
      });

      await orderService.addStageOrCorrection(id, formData, !!correction);

      dispatch(
        addNotification({
          id: 'order.addStage',
          status: AlertStatus.success,
          message: correction ? t('alert:addCorrection') : t('alert:addStage'),
        }),
      );

      onSuccess();
    } catch (error) {
      catchApiError(
        error,
        () =>
          dispatch(
            addNotification({
              id: 'order.addStage',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        setError,
      );
    } finally {
      dispatch(removeLoading(`STAGE_ACTION_${idOrder}`));
      dispatch(removeLoading(`STAGE_ACTION_TRIGGER_${id}`));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <File
        {...register('attachments')}
        id="attachments"
        label={`${t('text:addFileLabel')} *`}
        value={watch('attachments')}
        resetFn={() => setValue('attachments', null)}
        error={errors?.attachments}
      />
      <p className="text-12 xl:text-13 text-black-75 mb-8">{t('text:fileExtensions')}</p>
      <p className="text-12 xl:text-13 text-black-75">{t('text:fileSize')}</p>
      <div className="row mt-12 xl:mt-24">
        <div className="col w-auto mb-8 sm:mb-0">
          <Button as="submit" to="" loading={isSubmitting}>
            {t('button:addFile')}
          </Button>
        </div>
        <div className="col w-auto mb-8 sm:mb-0">
          <Button as="button" to="" secondary disabled={isSubmitting} onClick={onRequestClose}>
            {t('button:cancel')}
          </Button>
        </div>
      </div>
    </form>
  );
}

AddStageOfCorrection.defaultProps = defaultProps;

export default AddStageOfCorrection;
