import develtioLogo from './icons/develtio_logo.svg';
import arrowUp from './icons/arrow_up.svg';
import arrowDown from './icons/arrow_down.svg';
import arrowLeft from './icons/arrow_left.svg';
import arrowRight from './icons/arrow_right.svg';
import arrowLeftLong from './icons/arrow_left_long.svg';
import arrowLeftBold from './icons/arrow_left_bold.svg';
import arrowRightBold from './icons/arrow_right_bold.svg';
import facebook from './icons/facebook.svg';
import linkedin from './icons/linkedin.svg';
import twitter from './icons/twitter.svg';
import attachment from './icons/attachment.svg';
import download from './icons/download.svg';
import dashboard from './icons/dashboard.svg';
import marker from './icons/marker.svg';
import chat from './icons/chat.svg';
import user from './icons/user.svg';
import envelope from './icons/envelope.svg';
import phone from './icons/phone.svg';
import star from './icons/star.svg';
import clock from './icons/clock.svg';
import send from './icons/send.svg';
import check from './icons/check.svg';
import remove from './icons/remove.svg';
import logout from './icons/logout.svg';
import edit from './icons/edit.svg';
import close from './icons/close.svg';
import add from './icons/add.svg';
import menu from './icons/menu.svg';
import accepted from './icons/accepted.svg';
import badge from './icons/badge.svg';
import review from './icons/review.svg';
import waiting from './icons/waiting.svg';
import files from './icons/files.svg';
import userBadge from './icons/user_badge.svg';
import payments from './icons/payments.svg';
import unpaid from './icons/unpaid.svg';
import alertSuccess from './icons/alert_success.svg';
import alertInfo from './icons/alert_info.svg';
import alertError from './icons/alert_error.svg';

import footerInfo from './images/footer_info.png';
import notFound from './images/404.svg';
import authBg from './images/auth_bg.jpg';
import avatar from './images/avatar.jpg';
import doubleBg from './images/double_bg.png';
import doubleBg2 from './images/double_bg2.png';
import logo from './images/logo.jpeg';

export const icons: { [key: string]: string } = {
  develtioLogo,
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowLeftLong,
  arrowLeftBold,
  arrowRightBold,
  facebook,
  linkedin,
  twitter,
  attachment,
  download,
  dashboard,
  marker,
  chat,
  user,
  envelope,
  phone,
  star,
  clock,
  send,
  check,
  remove,
  logout,
  edit,
  close,
  add,
  menu,
  accepted,
  badge,
  review,
  waiting,
  files,
  userBadge,
  payments,
  unpaid,
  alertSuccess,
  alertInfo,
  alertError,
};

export const images: { [key: string]: string } = {
  footerInfo,
  notFound,
  authBg,
  avatar,
  doubleBg,
  doubleBg2,
  logo,
};
