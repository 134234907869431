import React from 'react';
import type { Order as OrderType, Badge, EmitOnChange } from 'helpers/types';
import { Box } from 'layouts';
import { ButtonLink } from 'components';
import { dateFormat } from 'helpers/date';
import type { Props as HeaderProps } from 'layouts/Box/Header';
import { useAppSelector } from 'hooks';
import { getOrderBadges } from 'features/badges/badgesSlice';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';
import { badgesService } from 'services';
import { catchApiError } from 'helpers/error';

export type Props = OrderType & {
  children?: React.ReactNode;
  badges?: Badge[] | null;
  open?: boolean;
};

const defaultProps = {
  children: '',
  badges: [],
  open: false,
};

const InfoCol = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <div className="col md:w-1/2 lg:w-1/5 mb-8">{children}</div>
);

function Order({
  id,
  children,
  open,
  label,
  title,
  user,
  badges,
  scopeOfOrder,
  typeOfStudy,
  field,
  pagesCount,
  term,
  additionalInfo,
  attachments,
}: Props): JSX.Element {
  const { t } = useTranslation(['badge']);
  const orderBadges = useAppSelector(getOrderBadges(id))
    .map((b): Badge | null => {
      if (b.type === 'chat') {
        return {
          title: t('badge:messages'),
          status: AlertStatus.success,
        };
      }

      if (b.type === 'attachment') {
        return {
          title: t('badge:attachment'),
          status: AlertStatus.success,
        };
      }

      if (b.type === 'offer') {
        return {
          title: t('badge:offer'),
          status: AlertStatus.success,
        };
      }

      return null;
    })
    .filter(Boolean) as Badge[];

  if (badges) {
    orderBadges.push(...badges);
  }

  const renderInfo = (l: string, v: string | number): JSX.Element => {
    return (
      <>
        <Box.Label>{l}</Box.Label>
        <Box.Text>{v}</Box.Text>
      </>
    );
  };

  const renderAttachments = (): JSX.Element => {
    return (
      <div className="mt-12 xl:mt-16">
        <Box.Label>Załączniki</Box.Label>
        <div className="row">
          {attachments.map((el) => (
            <div key={el.id} className="col md:w-1/2 xxl:w-1/4 mb-8">
              <ButtonLink to={el.src} as="download">
                {el.name}
              </ButtonLink>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleBoxToggle = async ({ value }: EmitOnChange) => {
    if (value) {
      try {
        await badgesService.clearOrderBadges(id);
        document.dispatchEvent(new CustomEvent('userAction'));
      } catch (error) {
        catchApiError(error);
      }
    }
  };

  return (
    <Box
      open={open}
      header={(p: HeaderProps) => (
        <Box.Header label={label} title={title} user={user} badges={orderBadges} {...p} />
      )}
      onChangeEmit={handleBoxToggle}
    >
      <div className="row">
        {scopeOfOrder && <InfoCol>{renderInfo('Zakres zlecenia', scopeOfOrder.name)}</InfoCol>}
        {typeOfStudy && <InfoCol>{renderInfo('Rodzaj opracowania', typeOfStudy.name)}</InfoCol>}
        {field && <InfoCol>{renderInfo('Dziedzina', field.name)}</InfoCol>}
        {pagesCount && <InfoCol>{renderInfo('Ilość stron', pagesCount)}</InfoCol>}
        {term && <InfoCol>{renderInfo('Termin realizacji', dateFormat(term))}</InfoCol>}
      </div>
      {additionalInfo ? (
        <div className="mt-12 xl:mt-16">{renderInfo('Dodatkowe informacje', additionalInfo)}</div>
      ) : null}
      {attachments.length ? renderAttachments() : null}
      {children ? <div className="mt-16 xl:mt-24">{children}</div> : null}
    </Box>
  );
}

Order.defaultProps = defaultProps;

export default Order;
