import qs from 'qs';
import api, { ApiError, authService, withAuthorizationHeader } from 'services';
import type { ListApiResponse, ListRequest, ListResponse, Order } from 'helpers/types';
import { OrderList, PaymentStatus, Role, StageStatus } from 'helpers/enums';
import { mapOrder, mapStage } from './helpers';
import type { PaymentResponse, PaymentStatusResponse } from './types';

export const orders = async (
  params: ListRequest,
  type: OrderList,
): Promise<ListResponse<Order>> => {
  try {
    let url = '';

    if (authService.hasRole(Role.editor)) {
      switch (type) {
        case OrderList.ongoing:
          url = 'text-order/my/editor/accepted';
          break;
        case OrderList.offers:
          url = 'text-order/my/editor/created';
          break;
        case OrderList.finished:
          url = 'text-order/my/editor/finished';
          break;
        default:
      }
    } else {
      switch (type) {
        case OrderList.ongoing:
          url = 'text-order/my/client/in-progress';
          break;
        case OrderList.offers:
          url = 'text-order/my/client/created';
          break;
        case OrderList.finished:
          url = 'text-order/my/client/finished';
          break;
        default:
      }
    }

    const { records, total }: ListApiResponse = await api
      .get(url, {
        headers: withAuthorizationHeader(),
        searchParams: qs.stringify(params),
      })
      .json();

    return {
      items: records.map(mapOrder),
      total,
    };
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const search = async (params: ListRequest): Promise<ListResponse<Order>> => {
  try {
    const { records, total }: ListApiResponse = await api
      .get('text-order/search', {
        headers: withAuthorizationHeader(),
        searchParams: qs.stringify(params),
      })
      .json();

    return {
      items: records.map(mapOrder),
      total,
    };
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const addOrder = async (data: FormData): Promise<void> => {
  try {
    await api.post('text-order', {
      headers: withAuthorizationHeader(),
      body: data,
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const fetchOrder = async (id: number): Promise<Order> => {
  try {
    const response = await api.get(`text-order/${id}`).json();

    return mapOrder(response);
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const addStageOrCorrection = async (
  id: number,
  data: FormData,
  correction: boolean,
): Promise<void> => {
  try {
    await api.post(`stage/${id}/${correction ? 'correction' : 'attachment'}`, {
      headers: withAuthorizationHeader(),
      body: data,
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const acceptStage = async (id: number): Promise<void> => {
  try {
    await api.post(`stage/${id}/finish`, {
      headers: withAuthorizationHeader(),
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const payStage = async (id: number): Promise<PaymentResponse> => {
  try {
    return await api
      .post(`payment/stage/${id}`, {
        headers: withAuthorizationHeader(),
      })
      .json();
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const paymentStatus = async (id: number): Promise<PaymentStatusResponse> => {
  try {
    const { id: idPayment, status, textOrderStage } = await api
      .get(`payment/${id}`, {
        headers: withAuthorizationHeader(),
      })
      .json();

    return {
      id: idPayment,
      status,
      order: mapOrder(textOrderStage.textOrder),
      stage: {
        ...mapStage(textOrderStage),
        status: status === PaymentStatus.paid ? StageStatus.paid : StageStatus.unpaid,
      },
    };
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const addReview = async (
  id: number,
  data: {
    rate: number;
    message: string;
  },
): Promise<void> => {
  try {
    await api.post(`text-order/${id}/rate`, {
      headers: withAuthorizationHeader(),
      json: data,
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};
