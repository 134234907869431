import React from 'react';
import { Button, Icon } from 'components';
import { Link } from 'react-router-dom';
import { images } from 'assets';
import { authService } from 'services';

function Footer(): JSX.Element {
  return (
    <footer className="bg-primary-lt">
      <div className="pt-32 pb-40 xl:py-72">
        <div className="container">
          <div className="row">
            <div className="col w-2/3 xl:w-5/6">
              <div className="row">
                <div className="col hidden xl:block xl:w-2/4">
                  <div
                    className="-ml-32 pl-32 pb-32 bg-no-repeat"
                    style={{ backgroundImage: `url(${images.footerInfo})` }}
                  >
                    <img src={images.logo} alt="logo" className="h-40 mb-16" />
                    <h3 className="text-black text-28 leading-36 font-bold font-headings">
                      Masz pytania?
                      <br />
                      Daj nam znać!
                    </h3>
                    <div className="mt-20">
                      <Button as="route" to="/kontakt" secondary>
                        Skontaktuj się z nami
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col md:w-1/2 xl:w-1/4">
                  <h3 className="hidden md:block text-black text-20 font-bold font-headings mb-16">
                    Informacje
                  </h3>
                  <ul>
                    <li className="mb-16">
                      <Link to="/o-platformie" className="text-16 font-semibold text-black-50">
                        O platformie
                      </Link>
                    </li>
                    <li className="mb-16">
                      <Link to="/zostan-redaktorem" className="text-16 font-semibold text-black-50">
                        Zostań redaktorem
                      </Link>
                    </li>
                    <li className="mb-16">
                      <Link to="/faq" className="text-16 font-semibold text-black-50">
                        FAQ
                      </Link>
                    </li>
                    <li className="mb-16">
                      <Link to="/artykuly" className="text-16 font-semibold text-black-50">
                        Artykuły
                      </Link>
                    </li>
                    <li className="mb-16">
                      <Link to="/kontakt" className="text-16 font-semibold text-black-50">
                        Kontakt
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col md:w-1/2 xl:w-1/4">
                  <h3 className="hidden md:block text-black text-20 font-bold font-headings mb-16">
                    Prywatność
                  </h3>
                  <ul>
                    <li className="mb-16">
                      <Link
                        to="/polityka-prywatnosci"
                        className="text-16 font-semibold text-black-50"
                      >
                        Polityka Prywatności
                      </Link>
                    </li>
                    <li className="mb-16">
                      <Link to="/regulamin" className="text-16 font-semibold text-black-50">
                        Regulamin
                      </Link>
                    </li>
                  </ul>
                  <div className="mt-24">
                    <Button
                      as="route"
                      to={authService.isLogin() ? '/klient/dodaj-zlecenie' : '/#zarejestruj-sie'}
                    >
                      Utwórz zlecenie
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col w-1/3 xl:w-1/6 text-right">
              <ul className="inline-block">
                <li className="mb-16">
                  <Button as="link-out" to="https://facebook.com" icon="facebook" secondary />
                </li>
                <li className="mb-16">
                  <Button as="link-out" to="https://twitter.com" icon="twitter" secondary />
                </li>
                <li className="mb-16">
                  <Button as="link-out" to="https://linkedin.com" icon="linkedin" secondary />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-16 pb-24 md:pb-16 border-t-2 border-white">
        <div className="container">
          <div className="row justify-between items-baseline text-center md:text-left">
            <div className="col md:w-1/2 mb-8 md:mb-0">
              <p className="text-12 xl:text-13 text-black-75">
                Copyright &copy; 2021 Pisanie-Online.com
              </p>
            </div>
            <div className="col md:w-1/2 md:text-right">
              <div className="inline-flex items-center">
                <p className="text-12 xl:text-13 text-black-75">Projekt i wykonanie:</p>
                <a href="https://develtio.com" target="_blank" rel="noopener noreferrer">
                  <Icon
                    icon="develtioLogo"
                    className="ml-12"
                    beforeInjection={(svg) => {
                      svg.classList.add('h-24');
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
