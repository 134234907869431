import React, { useEffect } from 'react';
import './LayoutDashboard.scss';
import { MenuMobile, PageContent, Sidebar } from 'layouts';
import { Link } from 'react-router-dom';
import { Icon } from 'components';

type Props = {
  children: React.ReactNode;
  renderControls: React.ReactNode;
};

function LayoutDashboard({ children, renderControls }: Props): JSX.Element {
  useEffect(() => {
    document.body.classList.add('layout-dashboard-body');

    return () => {
      document.body.classList.remove('layout-dashboard-body');
    };
  }, []);

  const renderSharedLinks = () => (
    <ul className="text-12 xl:text-16 text-black font-semibold mt-16">
      <li className="mb-24">
        <Link to="/" className="inline-flex items-center justify-center">
          <Icon
            icon="arrowLeftLong"
            className="pointer-events-none mr-12"
            beforeInjection={(svg) => {
              svg.classList.add('w-12', 'h-12', 'xl:w-16', 'xl:h-16', 'fill-black');
            }}
          />
          Powrót do pisanie-online.com
        </Link>
      </li>
      <li className="mb-24 pl-28">
        <Link to="/polityka-prywatnosci">Polityka Prywatności</Link>
      </li>
      <li className="mb-24 pl-28">
        <Link to="/regulamin">Regulamin</Link>
      </li>
    </ul>
  );

  return (
    <>
      <MenuMobile>
        <div className="flex flex-col justify-between h-full">
          <div>{renderControls}</div>
          <div>{renderSharedLinks()}</div>
        </div>
      </MenuMobile>
      <Sidebar>
        <div className="flex flex-col justify-between h-full">
          <div>{renderControls}</div>
          <div>{renderSharedLinks()}</div>
        </div>
      </Sidebar>

      <PageContent>
        <div className="container container--dashboard">{children}</div>
      </PageContent>
    </>
  );
}

export default LayoutDashboard;
