import React, { useEffect, useState } from 'react';
import { PageTitle, Loader } from 'components';
import { useTranslation } from 'react-i18next';
import type { Message as MessageType, Order, User } from 'helpers/types';
import { useParams } from 'react-router-dom';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { authService, chatService, orderService } from 'services';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { AlertStatus } from 'helpers/enums';
import { catchApiError } from 'helpers/error';
import { useAppDispatch, useLoading } from 'hooks';
import Loading from 'features/loading/Loading';
import { ChatAddMessage } from 'forms';
import Message from './Message';

type Params = {
  idOrder: string;
  idOffer: string;
};

const Chat = (): JSX.Element => {
  const { idOrder, idOffer } = useParams<Params>();
  const loading = useLoading();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['text']);
  const [user, setUser] = useState<User>();
  const [order, setOrder] = useState<Order>();
  const [messages, setMessages] = useState<MessageType[]>();

  const fetchOrder = async () => {
    try {
      dispatch(addLoading({ id: 'CHAT_FETCH_ORDER' }));

      const response = await orderService.fetchOrder(+idOrder);

      setOrder(response);
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'chat.fetchOrder',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    } finally {
      dispatch(removeLoading('CHAT_FETCH_ORDER'));
    }
  };

  const fetchMessages = async () => {
    try {
      dispatch(addLoading({ id: 'CHAT_FETCH_MESSAGES' }));

      const params = {
        sortField: 'a.created',
        sortDirection: 'DESC',
      };

      const response = await chatService.fetch(+idOffer, params);

      setMessages(response.items);

      document.dispatchEvent(new CustomEvent('userAction'));
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'chat.fetchOrder',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    } finally {
      dispatch(removeLoading('CHAT_FETCH_MESSAGES'));
      window.scrollTo(0, document.body.offsetHeight);
    }
  };

  useEffect(() => {
    setUser(authService.getUser() as User);

    fetchOrder();
    fetchMessages();
  }, []);

  return (
    <div className="xl:-mb-96">
      <PageTitle
        sticky
        description={
          <div className="text-black-75 leading-25 xl:leading-28">
            {loading.check(['CHAT_FETCH_ORDER']) ? (
              <Loader small />
            ) : (
              <p>
                <strong>{t('text:order')}: </strong> {order?.title}
              </p>
            )}
          </div>
        }
      >
        Czat
      </PageTitle>
      <Loading items={['CHAT_FETCH_MESSAGES']}>
        {messages ? (
          <div className="pb-16">
            {messages.map((m) => (
              <Message key={m.id} {...m} owner={m.user.id === user?.id} />
            ))}
          </div>
        ) : null}
        <div className="bg-white fixed xl:sticky w-full bottom-0 left-0 py-12 px-16 xl:py-24 xl:px-0 border-t border-black-10 xl:border-none -mb-12 xl:-mb-16">
          <ChatAddMessage id={+idOffer} onSuccess={fetchMessages} />
        </div>
      </Loading>
    </div>
  );
};

export default Chat;
