import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'components';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  as: 'button' | 'link' | 'link-out' | 'route' | 'download';
  to: string;
  icon?: string;
  disabled?: boolean;
  className?: string;
  [x: string]: any;
};

const defaultProps = {
  icon: '',
  disabled: false,
  className: '',
};

const defaultStyle = classNames([
  'group inline-flex items-center justify-center',
  'font-semibold text-15 xl:text-16 text-primary hover:text-primary-dk',
]);

function ButtonLink({
  children,
  as,
  to,
  icon,
  disabled,
  className: classNameExtend,
  ...rest
}: Props): JSX.Element | null {
  const className = classNames(defaultStyle, classNameExtend, {
    'opacity-20 pointer-events-none': disabled,
  });

  const renderIcon = (iconOverride = icon): JSX.Element | null => {
    if (!iconOverride) return null;

    return (
      <Icon
        icon={iconOverride}
        className="pointer-events-none mr-8"
        beforeInjection={(svg) => {
          svg.classList.add('w-16', 'h-16', 'fill-primary', 'group-hover:fill-primary-dk');
        }}
      />
    );
  };

  switch (as) {
    case 'button':
      return (
        <button type="button" className={className} {...rest}>
          {renderIcon()}
          {children}
        </button>
      );
    case 'link':
      return (
        <a href={to} className={className} {...rest}>
          {renderIcon()}
          {children}
        </a>
      );
    case 'link-out':
      return (
        <a href={to} target="_blank" rel="noopener noreferrer" className={className} {...rest}>
          {renderIcon()}
          {children}
        </a>
      );
    case 'route':
      return (
        <Link to={to} className={className} {...rest}>
          {renderIcon()}
          {children}
        </Link>
      );
    case 'download':
      return (
        <Link
          to={to}
          className={className}
          download
          target="_blank"
          rel="noopener noreferrer"
          {...rest}
        >
          <>
            {renderIcon('download')}
            {children}
          </>
        </Link>
      );
    default:
      return null;
  }
}

ButtonLink.defaultProps = defaultProps;

export default ButtonLink;
