import api, { withAuthorizationHeader } from 'services';

export const remove = async (id: number | string): Promise<void> => {
  try {
    await api.delete(`attachment/${String(id)}`, {
      headers: withAuthorizationHeader(),
    });
  } catch (error) {
    console.log('Fail remove attachment');
  }
};
