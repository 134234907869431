import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Input, Checkbox } from 'components';
import { useAppDispatch, useFieldsErrors } from 'hooks';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';
import { catchApiError } from 'helpers/error';
import { publicService } from 'services';

type Inputs = {
  name: string;
  email: string;
  content: string;
  rulesAccepted: boolean;
};

const schema = yup.object().shape({
  name: yup.string().required('To pole jest wymagane'),
  email: yup.string().email('Nieprawidłowy adres e-mail').required('To pole jest wymagane'),
  content: yup.string().required('To pole jest wymagane'),
  rulesAccepted: yup.mixed().oneOf([true], 'To pole jest wymagane'),
});

function Contact(): JSX.Element {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  useFieldsErrors('form-contact', errors);

  const onSubmit = async ({ name, email, content, rulesAccepted }: Inputs) => {
    try {
      await publicService.sendContact({
        name,
        email,
        content,
        rulesAccepted,
      });

      dispatch(
        addNotification({
          id: 'app.contact',
          status: AlertStatus.success,
          message: t('alert:contactSuccess'),
        }),
      );

      reset();
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'app.contact',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input control={control} name="name" placeholder="Imię i Nazwisko *" />
      <Input control={control} name="email" placeholder="Adres e-mail *" />
      <Input type="textarea" control={control} name="content" placeholder="Treść wiadomości *" />
      <Checkbox name="rulesAccepted" control={control} id="rulesAccepted">
        * Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
        ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id
        volutpat lacus laoreet.
      </Checkbox>
      <div className="mt-16">
        <Button as="submit" to="" loading={isSubmitting}>
          Wyślij wiadomość
        </Button>
      </div>
    </form>
  );
}

export default Contact;
