import Editor from 'pages/editor/Editor';
import Dashboard from 'pages/editor/Dashboard/Dashboard';
import Account from 'pages/editor/Account/Account';
import { Role } from 'helpers/enums';
import Orders from 'pages/editor/Orders/Orders';
import OrdersOngoing from 'pages/editor/Orders/Ongoing';
import OrdersOffers from 'pages/editor/Orders/Offers';
import OrdersFinished from 'pages/editor/Orders/Finished';
import Payments from 'pages/editor/Payments/Payments';
import PaymentsUnpaid from 'pages/editor/Payments/Unpaid';
import PaymentsPaid from 'pages/editor/Payments/Paid';
import Chat from 'pages/shared/Chat/Chat';

export default [
  {
    key: 'editor',
    path: '/redaktor',
    exact: false,
    component: Editor,
    withAuth: true,
    role: Role.editor,
    routes: [
      {
        key: 'editorDashboard',
        path: '/',
        exact: true,
        component: Dashboard,
      },
      {
        key: 'editorOrders',
        path: '/moje-zlecenia',
        exact: false,
        component: Orders,
        routes: [
          {
            key: 'editorOrdersOngoing',
            path: '/w-toku',
            exact: true,
            component: OrdersOngoing,
          },
          {
            key: 'editorOrdersOffers',
            path: '/oferty',
            exact: true,
            component: OrdersOffers,
          },
          {
            key: 'editorOrdersFinished',
            path: '/zakonczone',
            exact: true,
            component: OrdersFinished,
          },
          {
            key: 'notfound',
            path: '*',
            exact: true,
            redirect: '/404',
          },
        ],
      },
      {
        key: 'editorPayments',
        path: '/rozliczenia',
        exact: false,
        component: Payments,
        routes: [
          {
            key: 'editorPaymentsUnpaid',
            path: '/nieoplacone',
            exact: true,
            component: PaymentsUnpaid,
          },
          {
            key: 'editorPaymentsPaid',
            path: '/oplacone',
            exact: true,
            component: PaymentsPaid,
          },
          {
            key: 'notfound',
            path: '*',
            exact: true,
            redirect: '/404',
          },
        ],
      },
      {
        key: 'editorAccount',
        path: '/moje-dane',
        exact: true,
        component: Account,
      },
      {
        key: 'editorChat',
        path: '/chat/:idOrder/:idOffer',
        exact: true,
        component: Chat,
      },
      {
        key: 'notfound',
        path: '*',
        exact: true,
        redirect: '/404',
      },
    ],
  },
];
