import React, { useEffect } from 'react';
import { removeOne } from 'features/notifications/notificationsSlice';
import { useAppDispatch } from 'hooks';
import { IconAlert } from 'components';
import { AlertStatus } from 'helpers/enums';

export type Props = {
  status: AlertStatus;
  id: string;
  message: string;
  description?: string;
};

const defaultProps = {
  description: '',
};

function Notification({ status, id, message, description }: Props): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeOne(id));
    }, 5000);
  }, []);

  return (
    <div role="alert" className="shadow rounded-2 bg-white py-16 px-24 mb-16 animate-notification">
      <div className="flex">
        <div className="-mt-2 mr-8">
          <IconAlert status={status} />
        </div>
        <div>
          <p>{message}</p>
          {description && <p className="text-13 mt-12">{description}</p>}
        </div>
      </div>
    </div>
  );
}

Notification.defaultProps = defaultProps;

export default Notification;
