import type { Payment } from 'helpers/types';
import { OfferStatus } from 'helpers/enums';
import { mapOffer } from 'services/order/helpers';

export const mapPayment = ({
  id,
  subject,
  user,
  field,
  term,
  penaltyDays,
  penaltyAmount,
  offers,
}: any): Payment => {
  const price = offers
    ? offers.filter((n: any) => n.status !== OfferStatus.canceled).map(mapOffer).price
    : 0;
  return {
    id,
    title: subject,
    user: user
      ? {
          id: user.id,
          name: user.user.name,
          profilePhoto: null,
        }
      : null,
    label: field ? field.name : null,
    price,
    term,
    delay: penaltyDays || 0,
    penalty: penaltyAmount || 0,
    toPay: Number.parseInt(price || 0, 10) + Number.parseInt(penaltyAmount || 0, 10),
  };
};
